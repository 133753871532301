export function isNonEmptyString(value) {
  return value && value.trim().length > 0;
}

export function buildDNSPrefetchScripts(list) {
  if (!list) {
    return [];
  }

  return list
    .split(',')
    .filter((host) => host.trim().length > 0)
    .map((host) => {
      const prefetchElement = document.createElement('link');
      prefetchElement.setAttribute('rel', 'dns-prefetch');
      prefetchElement.setAttribute('href', '//' + host);

      const preconnectElement = document.createElement('link');
      preconnectElement.setAttribute('rel', 'preconnect');
      preconnectElement.setAttribute('crossorigin', '');
      preconnectElement.setAttribute('href', 'https://' + host);

      return [prefetchElement, preconnectElement];
    })
    .flat();
}

export function buildZenDesk(id) {
  const zendeskScript = document.createElement('script');
  zendeskScript.onload = initZendesk;
  zendeskScript.id = 'ze-snippet';
  zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + id;
  return zendeskScript;
}

function initZendesk() {
  zE('webWidget', 'updateSettings', {
    webWidget: {
      contactForm: {
        suppress: true, // We do not want the Contact Form to be visible, the user should use UserSnap for feedback
      },
      color: {
        theme: 'f38b00',
      },
    },
  });
  zE('webWidget', 'helpCenter:setSuggestions', { url: true });
  zE('webWidget', 'hide');
  // When widget is closed, hide it again.
  zE('webWidget:on', 'close', function () {
    zE('webWidget', 'hide');
  });

  window.showZendesk = function showZendesk() {
    zE('webWidget', 'show');
    zE('webWidget', 'open');
  };
}

export function buildCookieConsentManager(cust_id, config_id) {
  const result = [];
  const consentPreloadScript = document.createElement('script');
  consentPreloadScript.innerText =
    ";(function(w,o,d){w[o]=w[o]||function(){w[o][d].push(arguments)};w[o][d]=w[o][d]||[]})(window,'Osano','data');";
  result.push(consentPreloadScript);
  const consentScript = document.createElement('script');
  consentScript.setAttribute('id', 'CookieConsentManager');
  consentScript.setAttribute('src', `https://cmp.osano.com/${cust_id}/${config_id}/osano.js`);
  consentScript.setAttribute('type', 'text/javascript');
  result.push(consentScript);
  return result;
}
