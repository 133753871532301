import { initAmplitudeClient } from './scw-angular/analytics/amplitude-client';
import {
  buildCookieConsentManager,
  buildDNSPrefetchScripts,
  buildZenDesk,
  isNonEmptyString,
} from './scw-angular/util/config-script-builders';

const buildHead = () => {
  const env = window.SCW_ENV;
  const appConfigElement = document.getElementById('app-config');
  const elements = [];
  const { OSANO_CUST_ID, OSANO_CONFIG_ID } = env;

  if (isNonEmptyString(OSANO_CUST_ID) && isNonEmptyString(OSANO_CONFIG_ID)) {
    elements.push(...buildCookieConsentManager(OSANO_CUST_ID, OSANO_CONFIG_ID));
  }

  if (isNonEmptyString(env.DNS_PREFETCH_LIST)) {
    elements.push(...buildDNSPrefetchScripts(env.DNS_PREFETCH_LIST));
  }

  if (isNonEmptyString(env.ZENDESK_ID)) {
    elements.push(buildZenDesk(env.ZENDESK_ID));
  }

  let currentElement = appConfigElement;

  elements.forEach((element) => {
    // insert element after currentElement
    currentElement.parentNode.insertBefore(element, currentElement.nextSibling);
    currentElement = element;
  });

  initHead();
};

const initHead = () => {
  /**
   * This script is for CSP (Content Security Policy) for third party inline scripts.
   * Because most of these inline scripts need to be injected variables that vary upon environments,
   * Calculating csp inline script hashes will be a pain.
   * As a result, we moved all these inline scripts into head.js/foot.js to avoid the dynamic hash calculations.
   *
   * Please add third party tag/script integration via head.js/foot.js.
   */

  // 1. Google Analytics
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.appendChild(a);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  const {
    Osano,
    SCW_ENV: { MUNCHKIN_ACCOUNT_ID },
    Munchkin,
  } = window;

  if (Osano) {
    Osano('onMarketing', () => {
      if (Munchkin && MUNCHKIN_ACCOUNT_ID) {
        Munchkin.init(MUNCHKIN_ACCOUNT_ID);
      }
    });
  }

  initAmplitudeClient(window);
};

buildHead();
